import axios from "axios";
import * as types from "../types/funnels.types";

export function fetchFunnelSettings(funnelId) {
  return {
    type: types.FETCH_FUNNEL_SETTINGS,
    payload: axios.get(`${process.env.REACT_APP_API_URL}/funnels/${funnelId}`),
  };
}

export function fetchFunnelProgress(funnelId) {
  return {
    type: types.FETCH_FUNNEL_PROGRESS,
    payload: axios.get(
      `${process.env.REACT_APP_API_URL}/funnels/${funnelId}/progress`
    ),
  };
}

export function createFunnel(
  programId,
  name,
  inputSources,
  questions,
  hiddenColumns,
  fieldsOrder,
  assignedUsers,
  operationBetweenInputSources,
  showAverageAndPreviousScore,
  excludedSubmissions,
  isFinalFunnel,
  start_date,
  end_date,
  mobileVisible
) {
  return {
    type: types.CREATE_FUNNEL,
    payload: axios.post(`${process.env.REACT_APP_API_URL}/funnels`, {
      programId,
      name,
      inputSources,
      questions,
      hiddenColumns,
      fieldsOrder,
      assignedUsers,
      operationBetweenInputSources,
      showAverageAndPreviousScore,
      excluded_submissions: excludedSubmissions,
      is_final: isFinalFunnel,
      start_date,
      end_date,
      mobileVisible,
    }),
  };
}

export function saveFunnel(
  funnelId,
  name,
  inputSources,
  questions,
  hiddenColumns,
  fieldsOrder,
  assignedUsers,
  operationBetweenInputSources,
  questionsUpdated,
  showAverageAndPreviousScore,
  excludedSubmissions,
  isFinalFunnel,
  start_date,
  end_date,
  funnelGroupId,
  superFunnelId,
  inheritAssignedUsers,
  inheritFieldsVisibility,
  mobileVisible
) {
  return {
    type: types.SAVE_FUNNEL,
    payload: axios.put(`${process.env.REACT_APP_API_URL}/funnels/${funnelId}`, {
      name,
      inputSources,
      questions,
      operationBetweenInputSources,
      hiddenColumns,
      fieldsOrder,
      assignedUsers,
      questionsUpdated,
      showAverageAndPreviousScore,
      excluded_submissions: excludedSubmissions,
      is_final: isFinalFunnel,
      start_date,
      end_date,
      funnelGroupId,
      superFunnelId,
      inheritAssignedUsers,
      inheritFieldsVisibility,
      mobileVisible,
    }),
  };
}

export function resetFunnelState() {
  return {
    type: types.RESET_FUNNEL_STATE,
  };
}
