import React, { Component } from "react";
import PropTypes from "prop-types";
import FunnelsSettings from "./FunnelsSettings";

class FunnelsSettingsContainer extends Component {
  constructor(props) {
    super(props);

    let { hiddenColumns } = props.funnelSettings;

    if (!props.isEditing) {
      hiddenColumns = props.fields.map((field) => field.id);
    }

    this.state = {
      id: props.funnelSettings.id,
      name: props.funnelSettings.name,
      inputSources: props.funnelSettings.inputSources,
      questions: props.funnelSettings.questions,
      hiddenColumns,
      assignedUsers: props.funnelSettings.assignedUsers.map((user) => user.id),
      operationBetweenInputSources:
        props.funnelSettings.operationBetweenInputSources,
      questionsUpdated: [],
      isEdited: false,
      excluded_submissions: props.funnelSettings.excludedSubmissions,
      fieldsOrder: props.funnelSettings.fieldsOrder,
      showAverageAndPreviousScore:
        props.funnelSettings.showAverageAndPreviousScore,
      is_final_funnel: props.funnelSettings.isFinal,
      start_date: props.funnelSettings.startDate,
      end_date: props.funnelSettings.endDate,
      mobileVisible: props.funnelSettings.mobileVisible,
    };
  }

  handleFieldChange =
    (fieldName) =>
    (event, newValue, editedQues = []) => {
      // make a copy of the object first to avoid changes by reference
      const nextState = { ...this.state };
      const {
        target: { value, checked, type },
      } = event;

      if (nextState.questionsUpdated.length !== 0 && editedQues.length === 0) {
        editedQues = [...nextState.questionsUpdated];
      }

      nextState[fieldName] = type === "checkbox" ? checked : newValue || value;

      if (fieldName === "is_final_funnel" && checked) {
        nextState.questionsUpdated = [];
      }
      this.setState(
        { ...nextState, questionsUpdated: editedQues, isEdited: true },
        () => {}
      );
    };

  saveHandler = (
    name,
    inputSources,
    questions,
    hiddenColumns,
    assignedUsers,
    operationBetweenInputSources,
    showAverageAndPreviousScore
  ) => {
    const { saveFunnelSettings } = this.props;
    const { questionsUpdated, fieldsOrder } = this.state;
    saveFunnelSettings(
      name,
      inputSources,
      questions,
      hiddenColumns,
      fieldsOrder,
      assignedUsers,
      operationBetweenInputSources,
      questionsUpdated.slice(0),
      showAverageAndPreviousScore,
      this.state.excluded_submissions,
      this.state.is_final_funnel,
      this.state.start_date,
      this.state.end_date,
      this.state.mobileVisible
    );
    // Reset the state for the edited questions.
    this.setState({ questionsUpdated: [], isEdited: false });
  };

  render() {
    const {
      fields,
      users,
      groups,
      closeFunnelSettings,
      isEditing,
      onDelete,
      onCopy,
      programId,
    } = this.props;
    const {
      name,
      inputSources,
      hiddenColumns,
      questionsUpdated,
      assignedUsers,
      questions,
      operationBetweenInputSources,
      isEdited,
      excluded_submissions,
      is_final_funnel,
      start_date,
      end_date,
      mobileVisible,
    } = this.state;

    return (
      <FunnelsSettings
        fields={fields}
        users={users}
        groups={groups}
        name={name}
        questions={questions}
        questionsUpdated={questionsUpdated}
        hiddenColumns={hiddenColumns}
        inputSources={inputSources}
        excludedSubmissions={excluded_submissions}
        is_final_funnel={is_final_funnel}
        start_date={start_date}
        end_date={end_date}
        mobileVisible={mobileVisible}
        assignedUsers={assignedUsers}
        operationBetweenInputSources={operationBetweenInputSources}
        handleFieldChange={this.handleFieldChange}
        saveFunnelSettings={this.saveHandler}
        closeFunnelSettings={closeFunnelSettings}
        onDelete={onDelete}
        onCopy={onCopy}
        isEditing={isEditing}
        isEdited={isEdited}
        programId={programId}
        showAverageAndPreviousScore={this.state.showAverageAndPreviousScore}
      />
    );
  }
}

FunnelsSettingsContainer.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  funnelSettings: PropTypes.object.isRequired,
  saveFunnelSettings: PropTypes.func.isRequired,
  closeFunnelSettings: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
};

export default FunnelsSettingsContainer;
