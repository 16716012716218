import LocalizedStrings from "react-localization";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const locale = cookies.get("locale");
const strings = new LocalizedStrings({
  en: {
    funnels: "Funnels",
    funnels_graph: "Funnels Graph",
    funnels_progress: "Funnels Progress",
    funnel_settings: "Funnel Settings",
    all_submissions_tab: "All Submissions",
    funnel_name: "Funnel Name",
    funnel_input_sources: "Input Sources",
    funnel_scoring_cards: "Scoring Cards",
    funnel_hide_show_columns: "Enabled Fields",
    funnel_assign: "Assign",
    funnel_input_source: "Input Source",
    funnel_new_filter: "New Filter",
    funnel_new_input_source: "New Input Source",
    save: "Save",
    cancel: "Cancel",
    yes: "Yes",
    delete_funnel: "Deleting Funnel!",
    all_funnel_data_will_deleted:
      "All funnel data will be deleting including scoring questions and evaluations",
    reviewed: "Reviewed",
    inProgress: "In Progress",
    no_assigned_users: "No Assigned Users",
    select_all: "Select All",
    clone: "Clone",
    delete: "Delete",
    loading: "Loading ...",
    Submissions_note: "Submissions are assigned to the latest reviewer",
    public_funnel: "Public Funnel",
    "Scoring Options": "Scoring Options",
    "submissions not included in sources":
      "Submissions not included in sources",

    is_final_funnel: "Final funnel",

    start_date: "Start Date",
    end_date: "End Date",
    funnel_dates: "Funnel Dates",

    mobileVisible: "Mobile Visible",
  },
  ar: {
    funnels: "المراحل",
    funnels_graph: "رسم المراحل",
    funnels_progress: "تقدم المراحل",
    funnel_settings: "اعدادات المرحلة",
    all_submissions_tab: "جميع المشاركات",
    funnel_name: "اسم المرحلة",
    funnel_input_sources: "معايير الدخول",
    funnel_scoring_cards: "أسئلة التقييم",
    funnel_hide_show_columns: "إظهار الحقول",
    funnel_assign: "التعيين",
    funnel_input_source: "مصدر الإدخال",
    funnel_new_filter: "معيار جديد",
    funnel_new_input_source: "مصدر إدخال جديد",
    save: "حفظ",
    cancel: "الغاء",
    yes: "نعم",
    delete_funnel: "حذف مرحلة التصفية؟",
    all_funnel_data_will_deleted:
      "سيتم حذف جميع بيانات مرحلة التصفيه, كأسئلة التقييم وجميع بيانات التقييم السابقه",
    reviewed: "تمت مراجعتها",
    inProgress: "متقدم",
    noـassigned_users: "متقدم",
    select_all: "اختر الكل",
    clone: "نسخ",
    delete: "حذف",
    loading: "قيد التحميل ...",
    Submissions_note: "سيتم تعيين المراجعة لآخر المراجعين",
    public_funnel: "مرحلة خارجية",
    "Scoring Options": "خيارات التقييم",
    "submissions not included in sources": "المشاركات التي لم تدخل في المصادر",

    is_final_funnel: "المرحلة النهائية",

    start_date: "تاريخ البداية",
    end_date: "تاريخ النهاية",
    funnel_dates: "تواريخ المرحلة",

    mobileVisible: "ظاهر على الجوال",
  },
});

const translate = (string) => strings[string] || string;

strings.setLanguage(locale || "en");
export default translate;
